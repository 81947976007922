<template>
  <div id="cloud_market">
    <div class="banner">
      <h1>全套WEB环境敏捷开发工具</h1>
      <p>
        OMS订单管理系统, TMS运输管理系统, WMS仓储管理系统,
        <br />
        BMS计费管理系统, OAS运营分析系统
      </p>
    </div>

    <div class="container">
      <div class="con_title">
        <div
          class="t_item"
          v-for="(item, index) in title"
          :key="index"
          @click="toContent(index)"
        >
          {{ item }}
          <div class="bottom" :class="{ bg: currentIndex === index }"></div>
        </div>
      </div>
      <div class="floor_1 floor">
        <div class="floor1_title">
          <div class="floor1_t1">OMS订单管理系统</div>
          <div class="floor1_t2">订单全生命周期过程管理与可视追踪</div>
        </div>
        <div class="floor1_main">
          <div class="top">
            <img src="~assets/image/cloudMarket/f1_1.png" alt="" />
          </div>
          <div class="bottom">
            <div class="item" v-for="(item, index) in data01" :key="index">
              <span>{{ item.title }}</span>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_2 floor">
        <div class="floor2_title">
          <div class="floor2_t1">TMS运输管理系统</div>
          <div class="floor2_t2">实现运输作业过程全场景管理应用</div>
        </div>
        <div class="floor2_main">
          <div class="left">
            <img src="~assets/image/cloudMarket/f2_1.png" alt="" />
          </div>
          <div class="right">
            <div class="item" v-for="(item, index) in data02" :key="index">
              <span>{{ item.title }}</span>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_3 floor">
        <div class="floor3_title">
          <div class="floor3_t1">WMS仓储管理系统</div>
          <div class="floor3_t2">实现平台化的仓储管理要求</div>
        </div>
        <div class="floor3_main">
          <div class="top">
            <img src="~assets/image/cloudMarket/f3_1.png" alt="" />
          </div>
          <div class="bottom">
            <div class="item" v-for="(item, index) in data03" :key="index">
              <span>{{ item.title }}</span>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_4 floor">
        <div class="floor4_title">
          <div class="floor4_t1">BMS计费管理系统</div>
          <div class="floor4_t2">精细化核算，运用数据指导经营决策</div>
        </div>
        <div class="floor4_main">
          <div class="left">
            <img src="~assets/image/cloudMarket/f4_1.png" alt="" />
          </div>
          <div class="right">
            <div class="item" v-for="(item, index) in data04" :key="index">
              <span>{{ item.title }}</span>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_5 floor">
        <div class="floor5_title">
          <div class="floor5_t1">OAS运营分析系统</div>
          <div class="floor5_t2">构建物流数据资产与应用体系</div>
        </div>
        <div class="floor5_main">
          <div class="left">
            <img src="~assets/image/cloudMarket/f5_1.png" alt="" />
          </div>
          <div class="right">
            <div class="item" v-for="(item, index) in data05" :key="index">
              <span>{{ item.title }}</span>
              <p>{{ item.des }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resolvePlanMixin } from "@/mixin.js";
export default {
  name: "CloudMarket",
  mixins: [resolvePlanMixin],
  data() {
    return {
      title: [
        "OMS订单管理系统",
        "TMS运输管理系统",
        "WMS仓储管理系统",
        "BMS计费管理系统",
        "OAS运营分析系统",
      ],
      data01: [
        {
          title: "资源集中并网组建物流网络",
          des: `
        集中管理仓储、运力，库存资源、协同内外部多主体、多仓库、多运段联合运营，平台化模式运作，提升企业物流整体能力。`,
        },
        {
          title: "无缝对接与精益的计划管控",
          des: `订单多渠道、多方式无缝对接，订单与业务产品规则自动匹配，自动拆合单、实时线路班次计划调度提醒，提高订单履约率。`,
        },
        {
          title: "智能任务调度与过程协同控制",
          des: `智能路由、任务调度、任务分解与合并、资源分配，任务链路管控协同、降低调度人员工作难度，发挥规模效益，节省成本。`,
        },
        {
          title: "全景数字监管与主动预警防范",
          des: `一码流通，从订单到交付全生命周期可视化跟踪、全链路时效、交付质量主动预警和自动审计，准确交付，提高客户满足度。 `,
        },
      ],
      data02: [
        {
          title: "全过程透明可视可追溯",
          des: `无缝对接上下游客户，全运作过程可视追溯跟踪，主动通知客户交付。
        `,
        },
        {
          title: "多维度实时计划控制面板",
          des: `订单计划、任务状态、交付状态、温控、位置路径多维度实时运营看板，主动监控，及时预警。`,
        },
        {
          title: "全流程管理系统应用覆盖",
          des: `计划调度、任务生成，任务指派、任务接受、出车、提货、装车、在途、交付、回车车检作业过程应用全覆盖。`,
        },
        {
          title: "自定义配置流程与规则的运力管理",
          des: `可配置车队/车辆，车务，规费、绩效等管理工作流程与监管策略，可自定义规则自动生成管理报表。`,
        },
        {
          title: "可视化调度与AI智能调度支持",
          des: `地图线路规划、基于规则自动拆合单、大数据和AI人工智能装载分车和自动路径规划等智能调度。 `,
        },
      ],
      data03: [
        {
          title: "多业态与多作业模式支持",
          des: ` 满足时尚、快消、医药、制造业、电商、母婴、冷链、第三方等业态仓储管理需求，支持生产、批发、连锁、零售仓储分拣与快运中转不同作业模式。`,
        },
        {
          title: "多仓与多货主的仓储平台",
          des: `   多物流中心、多仓库、多货主的集中仓储管理平台，信息互通、支持资源共享应用、快速开仓，多仓协同，仓间联动，支持业主间库内货权转移。`,
        },
        {
          title: "运营与作业管理全应用覆盖",
          des: `从基础管理、运营管理、流程管理全应用覆盖。内置资源、计划、任务、效率、响应及时性、压力、绩效等监控和预警组件，可自定义组装物流中心全景动态看板，数字化决策任务调度与流程监控。`,
        },
        {
          title: "无缝对接周边系统，插件式适配智能装备",
          des: ` 开放API，与周边或客户系统无缝对接。系统参数可配、策略可配、流程可定义及二次开发平台的支持，具备高扩展性，高效响应复杂业务场景。可自定义智能设备接口，插件式集成管理智能设备。 `,
        },
      ],
      data04: [
        {
          title: "费用集中管控，计费与业务过程融合",
          des: `费用集中共享管控，业务过程与计费过程一体化融合。支持多帐套、分类帐套管理。
        `,
        },
        {
          title: "标准费用核算流程与多方式和规则支持",
          des: `规范化服务项目、产品、合同协议管理，标准化核算流程。支持多种计费方式和自定义计费规则。`,
        },
        {
          title: "最小计量单元的精细成本核算",
          des: `统一成本核算口径，基于作业成本法的核算，精细到作业最小计量单元，支持任意扩展的核算维度。`,
        },
        {
          title: "过程闭环管理与全流程追溯",
          des: `费用从核算到核销全过程闭环管理，全流程追溯。自动生成经营相关报表。支持与财务系统无缝对接。`,
        },
      ],
      data05: [
        {
          title: "内置行业分析模型，精益分析决策",
          des: `以物流行业运营管理模型为驱动，提供的分析决策应用覆盖资源评价、运营压力、作业效率、交付时效、经营质量及KPI考核多个维度。`,
        },
        {
          title: "可视化开发工具组件",
          des: `内置丰富的行业应用模板及可视化在线开发工具，图形化可视编辑，可轻松研发实时监控预警、数据大屏、 即席查询、自动化报表、分析诊断多类型应用。`,
        },
        {
          title: "企业物流数据中台建设",
          des: `提供完整的管理应用与开发组件，数据交换、质量、指标、标签体系完全可配置， 数据集成、数据开发、数据应用全开发流程支持，将分散的数据快速形成数据资产及构建数据中台服务。`,
        },
        {
          title: "基于大数据架构的运算处理",
          des: `基于大数据架构，支撑高吞吐量的数据接入、海量数据处理能力与高并发的数据调用。`,
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
#cloud_market {
  width: 100%;
  font-family: Microsoft YaHei;

  background: #ffffff;
  .banner {
    position: relative;
    width: 100%;
    height: 3.62rem;
    background: #ffffff;
    background: url("../assets/image/cloudMarket/b_1.png") no-repeat;
    background-size: cover;
    padding: 0.91rem 0 0 3.3rem;
    h1 {
      font-size: 0.36rem;
      font-weight: bold;
      font-weight: bold;
      color: #ffffff;
    }
    p {
      font-size: 0.16rem;
      line-height: 0.26rem;
      color: #ffffff;
    }
  }
  .container {
    .con_title {
      position: sticky;
      top: -0.01rem;
      text-align: center;
      height: 0.7rem;
      line-height: 0.7rem;
      background: #ffffff;
      border: 0.01rem solid #e6e6e6;
      border-top: none;
      z-index: 100;
      .t_item {
        display: inline-block;
        height: 100%;
        cursor: pointer;
        font-size: 0.16rem;
        text-align: center;
        font-weight: bold;
        color: #666666;
        .bottom {
          width: 0.7rem;
          height: 0.02rem;
          margin-left: 0.32rem;
          margin-top: -0.02rem;
          background: transparent;
        }
      }
      .t_item:nth-of-type(n + 2) {
        margin-left: 0.83rem;
      }
    }
    [class^="floor_"] {
      width: 100%;
      padding-top: 0.01rem;
      [class$="_title"] {
        text-align: center;
        font-size: 0.4rem;
        font-weight: bold;
        margin-top: 1.2rem;
        color: #383838;
        [class$="_t1"] {
          font-size: 0.4rem;
          font-weight: bold;
          color: #232323;
        }
        [class$="_t2"] {
          height: 0.2rem;
          margin-top: 0.32rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: #323333;
        }
      }
      [class$="_main"] {
        margin: 0 auto;
        width: 12.78rem;
      }
    }

    //相同样式
    .floor_1,
    .floor_3 {
      .floor1_main,
      .floor3_main {
        .bottom {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.3rem;
          .item {
            width: 5.14rem;
            span {
              font-size: 0.16rem;
              font-weight: bold;
              color: #333333;
            }
            p {
              margin-top: 0.13rem;
              font-size: 0.14rem;
              font-weight: 400;
              color: #606266;
              line-height: 0.24rem;
            }
          }
          .item:nth-of-type(2n) {
            margin-left: 1.99rem;
          }
          .item:nth-of-type(n + 3) {
            margin-top: 0.3rem;
          }
        }
      }
    }
    //不同样式
    .floor_1 {
      height: 10.5rem;
      .floor1_main {
        margin-top: 0.55rem;
        .top {
          margin-left: 0.2rem;
          img {
            width: 13.03rem;
            height: 4.48rem;
          }
        }
        .bottom {
          margin-left: 0.2rem;
        }
      }
    }
    .floor_3 {
      height: 11.5rem;
      .floor3_main {
        margin-top: 0.3rem;
        .top {
          img {
            width: 13.24rem;
            height: 5.78rem;
          }
        }
        .bottom {
          margin-left: 0.25rem;
        }
      }
    }

    .floor_2,
    .floor_4,
    .floor_5 {
      .floor2_main,
      .floor4_main,
      .floor5_main {
        display: flex;
        .right {
          margin-top: 0.28rem;
          .item {
            width: 5.16rem;
            span {
              font-size: 0.16rem;
              font-weight: bold;
              color: #333333;
            }
            p {
              margin-top: 0.11rem;
              font-size: 0.14rem;
              font-weight: 400;
              color: #606266;
              line-height: 0.24rem;
            }
          }
        }
      }
    }

    .floor_2 {
      height: 8.49rem;
      background: #f7f7f7;
      .floor2_main {
        margin-top: 0.3rem;
        .left {
          img {
            width: 7.5rem;
            height: 5.09rem;
          }
        }
        .right {
          margin-left: 0.3rem;
          .item:nth-of-type(n + 2) {
            margin-top: 0.2rem;
          }
        }
      }
    }
    .floor_4 {
      height: 8.8rem;
      background: #f7f7f7;
      .floor4_main {
        margin-top: 0.3rem;
        .left {
          img {
            width: 7.24rem;
            height: 5.09rem;
          }
        }
        .right {
          margin-left: 0.38rem;
          .item:nth-of-type(n + 2) {
            margin-top: 0.4rem;
          }
        }
      }
    }
    .floor_5 {
      height: 8.8rem;
      .floor5_main {
        margin-top: 0.3rem;
        .left {
          img {
            width: 7.24rem;
            height: 5.09rem;
          }
        }
        .right {
          margin-left: 0.39rem;
        }
        .item:nth-of-type(n + 2) {
          margin-top: 0.3rem;
        }
      }
    }
  }
}
.bg {
  background: #134a9f !important;
}
</style>