//解决方案 tab栏切换
export const resolvePlanMixin = {
  data() {
    return {
      currentIndex: 0,
      title: ["行业痛点", "解决方案概述", "方案优势"],
      contentOffsets: [],
    }
  },
  computed: {
    floors() {
      return [...document.getElementsByClassName("floor")];
    },
  },
  methods: {
    //点击标题跳到对应内容
    toContent(index) {
      window.scrollTo(0, this.contentOffsets[index]);
      this.currentIndex = index;
    },
    //滚动窗口联动标题
    windowScroll() {
      let contOffset = this.contentOffsets;
      let len = contOffset.length;
      window.onscroll = () => {
        let windOffset = window.pageYOffset + 70;
        //[784, 1967, 3063,5000]
        //[709.3,1892,2988]
        contOffset.forEach((offset, i) => {
          if (windOffset >= contOffset[i] && windOffset < contOffset[++i]) {
            this.currentIndex = i - 1;
          }
        });
      };
    },
  },

  mounted() {
    this.contentOffsets = this.floors.map((t) => t.offsetTop);
    this.contentOffsets.push(10000);
    this.windowScroll();
  },
  destroyed() {
    window.onscroll = null;
  },

}
